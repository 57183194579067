
import { defineComponent } from 'vue'
import Copy from '@/assets/svg/copy.svg?inline'
import Delete from '@/assets/svg/delete.svg?inline'
import UnitInfo from '@/assets/svg/building-info.svg?inline'
import Info from '@/assets/svg/info.svg?inline'
import Unpublish from '@/assets/svg/unpublish.svg?inline'
import Publish from '@/assets/svg/publish.svg?inline'
import Link from '@/assets/svg/link.svg?inline'
import Calendar from '@/assets/svg/calendar.svg?inline'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import More from '@/assets/svg/more.svg?inline'

export default defineComponent({
  name: 'UnitTable',
  data () {
    return {
      publish: false,
      errorNewUnit: null,
      isModalAddSlots: false,
      unitIdForSlots: null,
      timeslot: false
    }
  },
  props: {
    unitData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    deleteUnit: {
      type: Function,
      default: () => {
        return false
      }
    },
    publishUnit: {
      type: Function,
      default: () => {
        return false
      }
    },
    unpublishUnit: {
      type: Function,
      default: () => {
        return false
      }
    },
    openHandoverLink: {
      type: Function,
      default: () => {
        return false
      }
    },
    openVisitLink: {
      type: Function,
      default: () => {
        return false
      }
    },
    handleChooseBuilding: {
      type: [Function, Boolean],
      default: false
    },
    handleGoToBuilding: {
      type: [Function, Boolean],
      default: false
    },
    copyUnit: {
      type: [Function, Boolean],
      default: false
    },
    addressShow: {
      type: Boolean,
      default: true
    },
    errorUnit: null,
    unitId: null
  },
  components: {
    Copy,
    Delete,
    UnitInfo,
    Info,
    Publish,
    Unpublish,
    Link,
    Calendar,
    AlertBlock,
    DefaultPopup,
    More
  },
  methods: {
    goToUnitDetails (id) {
      this.$router.push(`/dashboard/unit-details?unitId=${id}`)
    },
    goToCreateSlots (id) {
      this.$router.push(`/dashboard/create-slots?unitId=${id}`)
    },
    popupCreateSlots (id, timeslot) {
      this.unitIdForSlots = id
      this.isModalAddSlots = true
      this.timeslot = timeslot
    },
    handleCloseModalAddSlots () {
      this.isModalAddSlots = false
    }
  }
})
