
import { defineComponent } from 'vue'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import Copy from '@/assets/svg/copy-link.svg?inline'

export default defineComponent({
  name: 'LinkPopup',
  data () {
    return {
    }
  },
  components: {
    DefaultPopup,
    Copy
  },
  props: {
    isModalLinkOpen: Boolean,
    closeModalLink: Function,
    selectedLanguageTempalte: Boolean,
    updateSlotsTempalte: Boolean,
    linkTempalte: Boolean,
    choosenLang: Function,
    copyLink: Function,
    goToCopyLinkTemplate: Function,
    linkCopied: Boolean,
    linkData: String,
    selectedLanguage: String,
    visitLink: String,
    updateSlots: Function,
    backToUpdateTemplate: Function,
    backToLangTemplate: Function,
    handover: Boolean,
    visit: Boolean
  },
  methods: {
  }
})
