import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04d6b2d5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "link-popup" }
const _hoisted_2 = { class: "link-popup__step" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = {
  key: 0,
  class: "link-popup__modal"
}
const _hoisted_7 = { class: "link-popup__title-lang" }
const _hoisted_8 = { class: "link-popup__popup-container-lang" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "link-popup__modal-text-update" }
const _hoisted_11 = { class: "link-popup__buttons-group" }
const _hoisted_12 = { key: 2 }
const _hoisted_13 = { class: "link-popup__modal-link-text" }
const _hoisted_14 = ["href"]
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "link-popup__link-text" }
const _hoisted_17 = { class: "link-popup__link" }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "link-popup__link-text" }
const _hoisted_20 = { class: "link-popup__link" }
const _hoisted_21 = {
  key: 2,
  class: "link-popup__link-container"
}
const _hoisted_22 = { class: "link-popup__link-text" }
const _hoisted_23 = { class: "link-popup__link" }
const _hoisted_24 = { class: "link-popup__link-text" }
const _hoisted_25 = { class: "link-popup__link" }
const _hoisted_26 = { class: "link-popup__link-copy" }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { key: 3 }
const _hoisted_29 = { class: "link-popup__modal-link-text" }
const _hoisted_30 = ["href"]
const _hoisted_31 = { key: 0 }
const _hoisted_32 = { class: "link-popup__link-text" }
const _hoisted_33 = { class: "link-popup__link" }
const _hoisted_34 = { key: 1 }
const _hoisted_35 = { class: "link-popup__link-text" }
const _hoisted_36 = { class: "link-popup__link" }
const _hoisted_37 = {
  key: 2,
  class: "link-popup__link-container"
}
const _hoisted_38 = { class: "link-popup__link-text" }
const _hoisted_39 = { class: "link-popup__link" }
const _hoisted_40 = { class: "link-popup__link-text" }
const _hoisted_41 = { class: "link-popup__link" }
const _hoisted_42 = { class: "link-popup__link-copy" }
const _hoisted_43 = { key: 0 }
const _hoisted_44 = { class: "link-popup__buttons-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Copy = _resolveComponent("Copy")!
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isModalLinkOpen)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 0,
          "on-close": _ctx.closeModalLink
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createTextVNode(_toDisplayString(_ctx.$t('message.step')) + " ", 1),
              (!_ctx.selectedLanguageTempalte && !_ctx.linkTempalte)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, "1"))
                : _createCommentVNode("", true),
              (_ctx.updateSlotsTempalte)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "2"))
                : _createCommentVNode("", true),
              (_ctx.linkTempalte)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, "3"))
                : _createCommentVNode("", true)
            ]),
            (!_ctx.selectedLanguageTempalte && !_ctx.linkTempalte)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('message.please_choose_language_of_invite')), 1),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", {
                      class: "link-popup__button first-button",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.choosenLang('german')))
                    }, _toDisplayString(_ctx.$t('message.property.german')), 1),
                    _createElementVNode("div", {
                      class: "link-popup__button first-button",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.choosenLang('english')))
                    }, _toDisplayString(_ctx.$t('message.property.english')), 1),
                    _createElementVNode("div", {
                      class: "link-popup__button first-button",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.choosenLang('combination')))
                    }, _toDisplayString(_ctx.$t('message.property.combination')), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.updateSlotsTempalte && _ctx.handover)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('message.property.update_slots_text')), 1),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", {
                      class: "link-popup__button third-button",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.backToLangTemplate()))
                    }, _toDisplayString(_ctx.$t('message.back')), 1),
                    _createElementVNode("div", {
                      class: "link-popup__button first-button",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateSlots()))
                    }, _toDisplayString(_ctx.$t('message.property.update_slots')), 1),
                    _createElementVNode("div", {
                      class: "link-popup__button second-button",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.goToCopyLinkTemplate()))
                    }, _toDisplayString(_ctx.$t('message.next')), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (!_ctx.handover && _ctx.visit && _ctx.updateSlotsTempalte)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('message.property.thanks_for_the_interest_in_the_property')), 1),
                  _createElementVNode("a", {
                    class: "link-popup__modal-link",
                    href: _ctx.visitLink,
                    target: "blanck"
                  }, [
                    (_ctx.selectedLanguage === 'german')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                          _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('message.join_our_fatflow_app_german')), 1),
                          _createTextVNode(),
                          _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.linkData), 1)
                        ]))
                      : (_ctx.selectedLanguage === 'english')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_18, [
                            _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t('message.join_our_fatflow_app')), 1),
                            _createTextVNode(),
                            _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.linkData), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_21, [
                            _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t('message.join_our_fatflow_app_german')), 1),
                            _createTextVNode(),
                            _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.linkData), 1),
                            _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t('message.join_our_fatflow_app')), 1),
                            _createTextVNode(),
                            _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.linkData), 1)
                          ]))
                  ], 8, _hoisted_14),
                  _createElementVNode("button", {
                    class: "link-popup__copy-button first-button",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.copyLink()))
                  }, [
                    _createVNode(_component_Copy),
                    _createTextVNode(_toDisplayString(_ctx.$t('message.copy')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    (_ctx.linkCopied)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(_ctx.$t('message.link_copied')), 1))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.linkTempalte)
              ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                  _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t('message.thanks_for_the_interest_in_the_property_handower')), 1),
                  _createElementVNode("a", {
                    class: "link-popup__modal-link",
                    href: _ctx.visitLink,
                    target: "blanck"
                  }, [
                    (_ctx.selectedLanguage === 'german')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_31, [
                          _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.$t('message.join_our_fatflow_app_german_handower')), 1),
                          _createTextVNode(),
                          _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.linkData), 1)
                        ]))
                      : (_ctx.selectedLanguage === 'english')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_34, [
                            _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.$t('message.join_our_fatflow_app_handower')), 1),
                            _createTextVNode(),
                            _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.linkData), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_37, [
                            _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.$t('message.join_our_fatflow_app_german_handower')), 1),
                            _createTextVNode(),
                            _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.linkData), 1),
                            _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.$t('message.join_our_fatflow_app_handower')), 1),
                            _createTextVNode(),
                            _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.linkData), 1)
                          ]))
                  ], 8, _hoisted_30),
                  _createElementVNode("button", {
                    class: "link-popup__copy-button first-button",
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.copyLink()))
                  }, [
                    _createVNode(_component_Copy),
                    _createTextVNode(_toDisplayString(_ctx.$t('message.copy')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_42, [
                    (_ctx.linkCopied)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_43, _toDisplayString(_ctx.$t('message.link_copied')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_44, [
                    _createElementVNode("div", {
                      class: "link-popup__button third-button",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.backToUpdateTemplate()))
                    }, _toDisplayString(_ctx.$t('message.back')), 1),
                    _createElementVNode("div", {
                      class: "link-popup__button second-button",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.closeModalLink()))
                    }, _toDisplayString(_ctx.$t('message.done')), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true)
  ]))
}