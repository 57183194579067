import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dcb593fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "building-card" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "building-card__svg-hint" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "building-card__publish-group"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 1,
  class: "building-card__publish-group"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "building-card__sher-link" }
const _hoisted_10 = { class: "building-card__sher-link" }
const _hoisted_11 = { class: "building-card__action-group" }
const _hoisted_12 = { class: "building-card__svg-group" }
const _hoisted_13 = { class: "building-card__svg-hint" }
const _hoisted_14 = { class: "building-card__svg-group" }
const _hoisted_15 = { class: "building-card__svg-hint" }
const _hoisted_16 = { class: "building-card__svg-group" }
const _hoisted_17 = { class: "building-card__svg-hint" }
const _hoisted_18 = { class: "building-card__svg-group" }
const _hoisted_19 = { class: "building-card__svg-hint" }
const _hoisted_20 = { class: "building-card__mobile-more" }
const _hoisted_21 = { class: "building-card__mobile-more-wrap" }
const _hoisted_22 = { class: "building-card__mobile-top-block" }
const _hoisted_23 = {
  key: 0,
  class: "building-card__publish-group"
}
const _hoisted_24 = ["onClick"]
const _hoisted_25 = {
  key: 1,
  class: "building-card__publish-group"
}
const _hoisted_26 = ["onClick"]
const _hoisted_27 = { class: "building-card__mobile-visit-block" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { class: "building-card__mobile-visit-block" }
const _hoisted_30 = { key: 0 }
const _hoisted_31 = { class: "building-card__mobile-bottom-block" }
const _hoisted_32 = { class: "building-card__svg-group" }
const _hoisted_33 = { class: "building-card__svg-hint" }
const _hoisted_34 = { class: "building-card__svg-group" }
const _hoisted_35 = { class: "building-card__svg-hint" }
const _hoisted_36 = { class: "building-card__svg-group" }
const _hoisted_37 = { class: "building-card__svg-hint" }
const _hoisted_38 = { class: "building-card__svg-group" }
const _hoisted_39 = { class: "building-card__svg-hint" }
const _hoisted_40 = { class: "building-card__modal" }
const _hoisted_41 = {
  key: 0,
  class: "building-card__modal-text"
}
const _hoisted_42 = {
  key: 1,
  class: "building-card__modal-text"
}
const _hoisted_43 = { class: "building-card__modal-buttons" }
const _hoisted_44 = { key: 2 }
const _hoisted_45 = { class: "building-card__modal" }
const _hoisted_46 = {
  key: 0,
  class: "building-card__modal-text"
}
const _hoisted_47 = {
  key: 1,
  class: "building-card__modal-text"
}
const _hoisted_48 = { class: "building-card__modal-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Info = _resolveComponent("Info")!
  const _component_Publish = _resolveComponent("Publish")!
  const _component_Unpublish = _resolveComponent("Unpublish")!
  const _component_Link = _resolveComponent("Link")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_UnitInfo = _resolveComponent("UnitInfo")!
  const _component_Copy = _resolveComponent("Copy")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_More = _resolveComponent("More")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_ctx.addressShow)
            ? (_openBlock(), _createElementBlock("th", _hoisted_2, _toDisplayString(_ctx.$t('message.property.building_address')), 1))
            : _createCommentVNode("", true),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('message.property.unit_name')), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('message.property.publish')), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('message.property.visit_link')), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('message.property.sh_link')), 1),
          _createElementVNode("th", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('message.actions')) + " ", 1),
            _createVNode(_component_Info, { class: "building-card__info" }),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('message.actions_hint_unit_table_building')), 1)
          ])
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unitData, (item) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: item.id
          }, [
            (_ctx.addressShow)
              ? (_openBlock(), _createElementBlock("td", _hoisted_4, _toDisplayString(item.address), 1))
              : _createCommentVNode("", true),
            _createElementVNode("td", null, _toDisplayString(item.name), 1),
            _createElementVNode("td", null, [
              (item?.status?.value === 'published')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_Publish),
                    _createElementVNode("div", {
                      class: "third-button",
                      onClick: ($event: any) => (_ctx.unpublishUnit(item.id))
                    }, _toDisplayString(_ctx.$t('message.property.unpublish')), 9, _hoisted_6)
                  ]))
                : _createCommentVNode("", true),
              (item?.status?.value !== 'published')
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_Unpublish),
                    _createElementVNode("div", {
                      class: "second-button",
                      onClick: ($event: any) => (_ctx.publishUnit(item.id, item.in_active_handover))
                    }, _toDisplayString(_ctx.$t('message.property.publish')), 9, _hoisted_8)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_9, [
                (item.visit_link)
                  ? (_openBlock(), _createBlock(_component_Link, {
                      key: 0,
                      onClick: ($event: any) => (_ctx.openVisitLink(item.id))
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_10, [
                (item.handover_link)
                  ? (_openBlock(), _createBlock(_component_Link, {
                      key: 0,
                      onClick: ($event: any) => (_ctx.openHandoverLink(item.id))
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("td", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_Calendar, {
                  onClick: ($event: any) => (_ctx.popupCreateSlots(item.id, item.timeslot))
                }, null, 8, ["onClick"]),
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('message.property.create_or_edit_slots')), 1)
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_UnitInfo, {
                  onClick: ($event: any) => (_ctx.goToUnitDetails(item.id))
                }, null, 8, ["onClick"]),
                _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('message.property.more_info_about_unit')), 1)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_Copy, {
                  onClick: ($event: any) => (_ctx.copyUnit(item.id))
                }, null, 8, ["onClick"]),
                _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('message.property.copy_units')), 1)
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_Delete, {
                  onClick: ($event: any) => (_ctx.deleteUnit(item.id))
                }, null, 8, ["onClick"]),
                _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('message.delete')), 1)
              ])
            ]),
            _createElementVNode("td", _hoisted_20, [
              _createVNode(_component_More),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  (item?.status?.value === 'published')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                        _createElementVNode("div", {
                          class: "third-button",
                          onClick: ($event: any) => (_ctx.unpublishUnit(item.id))
                        }, _toDisplayString(_ctx.$t('message.property.unpublish')), 9, _hoisted_24)
                      ]))
                    : _createCommentVNode("", true),
                  (item?.status?.value !== 'published')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                        _createElementVNode("div", {
                          class: "second-button",
                          onClick: ($event: any) => (_ctx.publishUnit(item.id))
                        }, _toDisplayString(_ctx.$t('message.property.publish')), 9, _hoisted_26)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_27, [
                    (item.visit_link)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(_ctx.$t('message.property.visit_link')), 1))
                      : _createCommentVNode("", true),
                    (item.visit_link)
                      ? (_openBlock(), _createBlock(_component_Link, {
                          key: 1,
                          onClick: ($event: any) => (_ctx.openVisitLink(item.id))
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_29, [
                    (item.handover_link)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(_ctx.$t('message.property.sh_link')), 1))
                      : _createCommentVNode("", true),
                    (item.handover_link)
                      ? (_openBlock(), _createBlock(_component_Link, {
                          key: 1,
                          onClick: ($event: any) => (_ctx.openHandoverLink(item.id))
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("div", _hoisted_32, [
                    _createVNode(_component_Calendar, {
                      onClick: ($event: any) => (_ctx.popupCreateSlots(item.id, item.timeslot))
                    }, null, 8, ["onClick"]),
                    _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t('message.property.create_or_edit_slots')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_34, [
                    _createVNode(_component_UnitInfo, {
                      onClick: ($event: any) => (_ctx.goToUnitDetails(item.id))
                    }, null, 8, ["onClick"]),
                    _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.$t('message.property.more_info_about_unit')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_36, [
                    _createVNode(_component_Copy, {
                      onClick: ($event: any) => (_ctx.copyUnit(item.id))
                    }, null, 8, ["onClick"]),
                    _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.$t('message.property.copy_units')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_38, [
                    _createVNode(_component_Delete, {
                      onClick: ($event: any) => (_ctx.deleteUnit(item.id))
                    }, null, 8, ["onClick"]),
                    _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.$t('message.delete')), 1)
                  ])
                ])
              ])
            ])
          ]))
        }), 128))
      ])
    ]),
    (_ctx.errorUnit)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 0,
          "on-close": _ctx.handleCloseModal
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_40, [
              (_ctx.errorUnit.context === 'unit_has_draft')
                ? (_openBlock(), _createElementBlock("div", _hoisted_41, _toDisplayString(_ctx.$t('message.property.unit_has_draft')), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_42, _toDisplayString(_ctx.$t('message.property.please_set_the_visit_slots_before_publishing_this_unit')), 1)),
              _createElementVNode("div", _hoisted_43, [
                (_ctx.errorUnit.context !== 'unit_has_draft')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModal && _ctx.handleCloseModal(...args))),
                      class: "building-card__modal-button third-button"
                    }, _toDisplayString(_ctx.$t('message.cancel')), 1))
                  : _createCommentVNode("", true),
                (_ctx.errorUnit.context === 'unit_has_draft')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModal && _ctx.handleCloseModal(...args))),
                      class: "building-card__modal-button building-card__modal-button-ok second-button"
                    }, _toDisplayString(_ctx.$t('message.ok')), 1))
                  : _createCommentVNode("", true),
                (_ctx.errorUnit.context !== 'unit_has_draft')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToCreateSlots(_ctx.unitId))),
                      class: "building-card__modal-button second-button"
                    }, _toDisplayString(_ctx.$t('message.property.create_slots')), 1))
                  : _createCommentVNode("", true)
              ]),
              (this.errorDeleteBuilding)
                ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                    _createVNode(_component_AlertBlock, {
                      message: this.errorDeleteBuilding.property
                    }, null, 8, ["message"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.isModalAddSlots)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 1,
          "on-close": _ctx.handleCloseModalAddSlots
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_45, [
              (!this.timeslot)
                ? (_openBlock(), _createElementBlock("div", _hoisted_46, _toDisplayString(_ctx.$t('message.property.want_to_add_visit_slots_now')), 1))
                : _createCommentVNode("", true),
              (this.timeslot)
                ? (_openBlock(), _createElementBlock("div", _hoisted_47, _toDisplayString(_ctx.$t('message.property.want_to_edit_visit_slots_now')), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_48, [
                _createElementVNode("div", {
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalAddSlots && _ctx.handleCloseModalAddSlots(...args))),
                  class: "building-card__modal-button third-button"
                }, _toDisplayString(_ctx.$t('message.cancel')), 1),
                _createElementVNode("div", {
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.goToCreateSlots(this.unitIdForSlots))),
                  class: "building-card__modal-button second-button"
                }, _toDisplayString(_ctx.$t('message.yes')), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true)
  ]))
}